.App {
  text-align: center;
  display: flex;
  height: 100vh;
  overflow: hidden;
}

.App-overlay {
  background-color: rgba(0, 0, 0, 0.5); /* 50% opacity black */
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999; /* make sure it's above everything else */
}

.App-loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
}

.App-content {
  width: 100%;
  margin-top: 60px;
}